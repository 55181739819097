.quote-view {
    padding-top: 20px;
    h4{
        margin-bottom: 40px;
        font-family: 'Ubuntu';
    }
    .mb-3 {
        position: relative; /* Required for label positioning */
        margin: 10px; /* Adjust as needed */
        padding: 10px;
        font-size: 14px; /* Adjust as needed */
  
        .form-label {
            position: absolute;
            top: -20px;
            left: 10px;
            background-color: transparent;
            padding: 5px 40px;
            color: #dab8b8;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-weight: bold;
            letter-spacing: 4px;
            text-transform: uppercase;
            font-size: 12px;
        }
        
        .form-text {
            /* border: 1px solid white; */
            /* border-radius: 5px; */
            color: rgb(235, 227, 227);
            margin: 5px;
            text-align: left;
            font-size: 16px;
            padding: 5px;
            margin-left: 50px;
            border-bottom: 1px solid white;
        }
    }    
}