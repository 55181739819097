/* Import the Ubuntu font styles */
@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu'), url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Sedgwick';
    src: local('Sedgwick'), url('./assets/fonts/Sedgwick/SedgwickAveDisplay-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Croissant';
    src: local('Croissant'), url('./assets/fonts/Croissant_One/CroissantOne-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'DelaGothic';
    src: local('DelaGothic'), url('./assets/fonts/Dela_Gothic_One/DelaGothicOne-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('./assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf') format('truetype');
}




/*
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
*/
