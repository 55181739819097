/* Add this line to import Font Awesome CSS */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css');

.App {
  text-align: center;
}

@font-face {
  font-family: 'Ubuntu'; /* Replace with the desired font name */
  src: url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('ubuntu'), /* Specify the correct file path and format */
       url('./assets/fonts/Ubuntu/Ubuntu-Bold.ttf') format('ubuntu-bold'); /* Add additional formats if needed */
  font-weight: normal;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.quote{
  font-family: 'Ubuntu', ubuntu;
  font-size: 48px;
}

