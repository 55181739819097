.main-options {
  .options-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    transform: translateY(-2%);
    transition: transform 0.3s ease-in-out;
  
    .btn-outline-primary{
      border-radius: 20px;
      border-color: white;
      color: white;
      padding: 7px;
      margin: 5px;
      min-width: 200px;
    }

    .btn{
      letter-spacing: normal;
      text-transform: uppercase;
      
      i{
        margin-right: 5px;
      }
    }
  }
  
  .options-container.show {
    transform: translateY(0);
  }
  
}

