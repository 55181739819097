/* RandomQuote.css */

.random-quote-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  /* For small mobile screens (up to 768px wide) */
  @media (max-width: 768px) {
    .random-quote-container {
      min-height: 90vh;  /*Adjust the percentage as needed */
    }
  }
  
  .options-button {
    position: absolute; /* Position absolutely within the container */
    bottom: 10px; /* Adjust this value to control the vertical position from the bottom */
    left: 10px;
    background-color: transparent;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refresh-button {
    position: absolute; /* Position absolutely within the container */
    bottom: 10px; /* Adjust this value to control the vertical position from the bottom */
    right: 10px; /* Adjust this value to control the horizontal position from the right */
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn{
    display: inline-block;
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Box shadow properties */
    transition: box-shadow 0.3s ease; /* Add a smooth transition effect */;
    font-size: 12px;
    letter-spacing: 4px;
    color: white;
    padding: 15px;
    border: 1px solid white;
    border-radius: 30px;
  }

  .btn:hover {
    box-shadow: 4px 4px 8px rgba(141, 112, 112, 0.3); /* Adjust shadow on hover */
    border: 1px solid rgb(246, 208, 208);
  }
  
  .options-button span {  

  }

  .refresh-button span {

  }
  
  .quote-text {
    font-size: 56px;
    font-weight: bold;
    text-align: center;
    padding-top: 20px; /* Space for the button */
  }

  .author{
    font-size: 0.7em;
    font-style: italic;
    font-family: 'Ubuntu';
    font-weight: normal;
    color: rgb(238, 231, 200);
  }
    