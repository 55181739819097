.quote-crud {
    width: 95%;
    max-width: 800px; /* Set the maximum width for desktop */
    margin: 0 auto; /* Center the container horizontally */
    font-family: 'Ubuntu';

    h4{
      letter-spacing: 3px;
      margin-bottom: 30px;
    }

    .mb-3 {
      position: relative; /* Required for label positioning */
      margin: 10px; /* Adjust as needed */
      padding: 10px;
      font-size: 14px; /* Adjust as needed */

      .form-label {
        position: absolute;
        top: -20px;
        left: 10px;
        background-color: transparent;
        padding: 5px 40px;
        color: #dab8b8;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-weight: bold;
        letter-spacing: 4px;
        text-transform: uppercase;
        font-size: 12px;
      } 
  
      .input-container {
        border: 1px solid #ced4da; /* Adjust border color */
      }
      
      .form-control {
        border: 1px solid white;
        border-radius: 5px;
        /* padding: 15px; */
        background-color: rgb(235, 227, 227);
        color: rgb(51, 48, 48);
      }

    }

    h2{
        margin-bottom: 20px;
    }

    .form-buttons {
      .btn{
        margin-right: 10px;
        min-width: 150px;
      }
      padding: 10px;
    }
    
    .form-check {
      display: block;
      min-height: 1.5em;
      padding-left: 0em;
      /* margin-bottom: 0.125rem; */
    } 
  }
  