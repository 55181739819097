.quote-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    font-size: 14px;
  }
  
  .quote-card {
    border: 1px solid #f7f7f7;
    border-radius: 20px;
    padding: 1rem;
    width: 100%;
    max-width: 300px; /* Adjust as needed */
    text-align: left;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #e0e0e0;
    }
  
    .quote-title {
      font-weight: bold;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      min-height: 75px;
    }
  
    .quote-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .quote-author {
        font-size: 0.75rem;
        color: #f7f7f7;
      }
  
      .quote-actions {
        display: flex;
        gap: 0.5rem;
  
        .quote-action-button {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 0.9rem;
          color: #f7f7f7;
  
          &:hover {
            color: #007bff;
          }
        }

        .icon-space{
            margin-left: 5px;
        }
      }
    }
  }
  